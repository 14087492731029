<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'customers-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau client
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col-auto">Nº</th>
          <th scope="col-2">Référence Nº</th>
          <th scope="col-4">Nom Complet</th>
          <th scope="col-1">Phone</th>
          <th scope="col-3">Type</th>
          <th scope="col-3"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer, index) in customers" :key="index++">
          <td class="col-auto">
            {{ index }}
          </td>
          <td class="col-2">
            {{ customer.reference }}
          </td>
          <td class="col-4">
            {{ customer.fullName }}
          </td>

          <td class="col-2">
            {{ customer.phone }}
          </td>
          <td class="col-3">
            <span v-if="customer.type">
              <button
                class="btn p-0 m-0"
                @click="
                  maasked_type == true
                    ? ((maasked_type = false), (display_type_customer = ''))
                    : ((maasked_type = true),
                      (display_type_customer = customer.reference))
                "
              >
                <i class="bi bi-eye fs-5"></i>
                {{
                  customer.type.fullName
                    | masked(
                      maasked_type,
                      customer.reference,
                      display_type_customer
                    )
                }}
              </button>
            </span>
          </td>

          <td class="col-2 d-flex">
            <button
              @click="
                $router.push({
                  name: 'customers-edit',
                  params: { reference: customer.reference },
                })
              "
              class="btn text-secondary"
            >
              <i class="bi bi-pencil-square"></i>
            </button>

            <button @click="remove(customer)" class="btn text-danger">
              <i class="bi bi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      maasked_type: false,
      search_value: "",
      addedToday: false,
      sortValue: "",
      display_type_customer: "",
    };
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("customer/getAll");
  },
  methods: {
    async remove(reference) {
      return await this.$store.commit("customer/destroy", reference);
    },

    async search(value) {
      return await this.$store.commit("customer/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("customer/getAll");
    },

    async sort(value) {
      return await this.$store.commit("customer/sort", value);
    },
     async remove(data) {
      let customer = data;
      await this.$confirm({
        message: "Vous voulez supprimer le client  " + customer.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("customer/destroy", customer.reference);
          }
        },
      });
    },
  },
  filters: {
    masked: function (value, maasked_type, reference, display_type_customer) {
      if (!maasked_type) return "*********";
      if (reference == display_type_customer) return value;

      if (reference != display_type_customer) return "*********";
    },
  },
};
</script>
